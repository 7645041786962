<!--
 * @Date: 2021-03-02 21:39:51
 * @LastEditors: frank
 * @LastEditTime: 2021-03-31 13:35:03
 * @FilePath: /shop_frontend/src/views/pageDecoration/card.vue
 * @Description: Description
-->
<template>
  <section class="card">
    <a-checkbox-group @change="onChange" v-model="checkedList">
      <a-row>
        <a-col :span="24" v-for="item in cardList" :key="item.id">
          <div class="box-side">
            <div style="width: 10em;">
              <a-checkbox :value="item.id">{{ item.name }} </a-checkbox>
            </div>
            <div class="box">
              <img :src="item.background_url" alt="" />
            </div>
          </div>
        </a-col>
      </a-row>
    </a-checkbox-group>
    <a-row style="margin: 20px 0">
      <a-col span="24">
        当前排序:
        <a-input v-model="index" class="w220" />
      </a-col>
    </a-row>
    <!-- <a-row>
      <a-col span="24"
        ><a-checkbox v-model="is_visible">是否显示</a-checkbox></a-col
      >
    </a-row> -->
    <div style="margin-top: 30px">
      <a-button type="primary" @click="submit"> 保存 </a-button>
       <a-button type="danger" @click="onDelete" style="margin-left: 10px;"> 删除 </a-button>
    </div>
  </section>
</template>

<script>
export default {
  name: "card",
  data() {
    return {
      cardList: [],
      checkedList: [],
      is_visible: false,
      index: "",
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  components: {},
  computed: {},
  watch: {
    // info : {
    //   handler: function () {
    //     console.log(this.info, 'info=w')
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  created() {
    this.getCards();
  },
  mounted() {
    console.log(this.info, "info");
    this.checkedList = [this.info.card];
    this.is_visible = this.info.is_visible;
    this.index = this.info.index;
  },
  methods: {
    onDelete () {
      this.$axios.delete('/card_modules/' + this.info.id + '/').then(() => {
        this.$emit('refreshDel')
      })
    },
    submit() {
      console.log(this.checkedList, "submit");
      this.$axios
        .put("/card_modules/" + this.info.id + '/', {
          card: this.checkedList[0],
          is_visible: this.is_visible,
          id: this.info.id,
          index: this.index,
        })
        .then(() => {
          this.$message.success("操作成功");
          this.$emit('refresh')
        });
    },
    onChange(checkedValues) {
      console.log("checked = ", checkedValues);
      this.checkedList = checkedValues.slice(-1)
    },
    getCards() {
      this.$axios
        .get("/cards/", { params: { page_size: 9999 } })
        .then((res) => {
          this.cardList = res.results || [];
        });
    },
  },
};
</script>

<style scoped lang="less">
.card {
  padding-left: 40px;
}
.box-side {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 20px;
}
.box {
  display: inline-block;
  width: 200px;
  height: 100px;
  background: #ddd;
  margin-left: 20px;
  border-radius: 10px;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}
</style>
